import './App.css';
import Bridge from './components/Bridge/Bridge';
import Nav from './components/Nav/Nav';
import { Navigate, Route, Routes } from 'react-router-dom';
import Withdraw from './components/Withdraw/Withdraw';

function App() {
  return (
    <>
      <Nav/>
      {/* <Bridge/> */}

      <Routes>
        <Route path="/" element={ <Bridge />}> </Route>
        <Route path="/Withdraw" element={ <Withdraw />}> </Route>
        <Route path='*' element={ <Navigate to='/' /> }></Route>
      </Routes>
    </>
  );
}

export default App;
