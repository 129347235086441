import { useState, useCallback } from "react";
import { useWriteContract } from "wagmi";
import { dragonAbi } from "../abis/dragon";
import config from "../config";

const contractAddress = config.dragonTokenAddress as `0x${string}`;
const dragonBridgeAddress = config.dragonBridgeAddress as `0x${string}`;

function useSetApprovalERC404() {
  const { status, error, data, writeContract } = useWriteContract();

  const handleApprove = useCallback(async () => {
    try {
      writeContract({
        abi: dragonAbi,
        address: contractAddress,
        functionName: "setApprovalForAll",
        args: [dragonBridgeAddress, true],
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return { status, error, data, handleApprove };
}

export default useSetApprovalERC404;
