import { useState, useCallback } from "react";
import { useWriteContract } from "wagmi";
import { useReadContract } from "wagmi";
import { dragonBridgeAbi } from "../abis/dragonBridge";
import config from "../config";
import { abi } from "../abis/dragonv2";

const contractAddress = config.dragonBridgeAddress as `0x${string}`;


function useGetLockedft() {

  const getlockednft = useCallback(async (tokenAddress: any) => {
 

    try {
      
        // const lockedDataNft = useReadContract({
        //     abi,
        //     address: contractAddress,
        //     functionName: 'getDepositIdsByDepositor',
        //     args: [tokenAddress],
        // })
    
        // console.log("lockedDataNft: ", lockedDataNft);


    } catch (error) {
      console.error(error);
    }
  }, []);

   return { getlockednft };
}

export default useGetLockedft;
