import "./Nav.css";
import { useState } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { HashLink as Link } from 'react-router-hash-link';
import Logo from "./Nav_Assets/logo.png";

function Nav() {

  return (
    <nav className="nav">
      <div className="nav__container">
        <div className="nav__left">
            <div className="nav__logo fire-color">
            {/* <img src={Logo}/> */}
            <Link to="/#top" ><img src={Logo} alt="Logo" ></img> </Link>
            </div>

        </div>

        <div className="nav__right">
            <div className="withdraw_btn">
                  <Link to="/Withdraw"> <p className="Withdraw">Withdraw</p> </Link>
            </div>

            <div className="nav__cta">
                <ConnectButton />
            </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
