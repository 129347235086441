import { useState, useCallback } from "react";
import { useWriteContract } from "wagmi";
import { erc721Abi } from "../abis/erc721";
import config from "../config";

function useApproveERC721() {
  const { data, status, writeContract } = useWriteContract();
  const ERC721contractAddress=config.erc721TestTokenAddress as `0x${string}`;

  const handleApprove = useCallback(async (address: any, tokenId: any) => {
    console.log("Approval Started");
    try {
      writeContract({
        abi: erc721Abi,
        address: ERC721contractAddress,
        functionName: "approve",
        args: [address, tokenId],
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

 

  return { status, data, handleApprove };
}

export default useApproveERC721;
