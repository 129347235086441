import { useCallback } from "react";
import { useWriteContract } from "wagmi";
import { dragonBridgeAbi } from "../abis/dragonBridge";
import config from "../config";

const contractAddress = config.dragonBridgeAddress as `0x${string}`;

function useWithdraw() {
  const { status, error, data, writeContract } = useWriteContract();

  const handleWithdraw = useCallback(async (depositId: any, tokenId: any) => {
    try {
      await writeContract({
        abi: dragonBridgeAbi,
        address: contractAddress,
        functionName: "withdrawERC721",
        args: [depositId, tokenId],
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return { status, error, data, handleWithdraw };
}

export default useWithdraw;
