import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import '@rainbow-me/rainbowkit/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
  getDefaultWallets,
  RainbowKitProvider,
  getDefaultConfig
} from '@rainbow-me/rainbowkit';
import { WagmiProvider, http } from 'wagmi'
//import { configureChains, createConfig, WagmiConfig } from 'wagmi';

import { mainnet, sepolia, polygonMumbai } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

// const { chains, publicClient } = configureChains(
//   [mainnet, sepolia],
//   [
//     alchemyProvider({ apiKey: "PRvrTo5Mqehu0WC5gbMvMx9ZsISAhozA" }),
//     publicProvider()
//   ]
// );

const config = getDefaultConfig({
  appName: 'RainbowKit demo',
  projectId: '241bb4581819090d1602501778f5ff8f',
  chains: [mainnet, sepolia, polygonMumbai],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
    [polygonMumbai.id]: http(),
  },
})


// const { connectors } = getDefaultWallets({
//   appName: 'My RainbowKit App',
//   projectId: '241bb4581819090d1602501778f5ff8f',
//   chains
// });

// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors,
//   publicClient
// })
const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>

    <WagmiProvider config={config}>
       <QueryClientProvider client={queryClient}>
         <RainbowKitProvider>
        <App />
        </RainbowKitProvider>
       </QueryClientProvider>
       </WagmiProvider>
    </BrowserRouter>
  </React.StrictMode>
);



