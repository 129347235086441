
import "./Bridge.css";
import { ethers } from "ethers";
import { Network, Alchemy } from 'alchemy-sdk';
import { useAccount, useTransactionConfirmations } from "wagmi";
import React, { useRef, useEffect, useState } from 'react';


import useDeposit from "../hooks/useDeposit";
import config from "../config";
import useApproveERC721 from "../hooks/useApproveERC721";

import { 
    useSendTransaction, 
    useWaitForTransactionReceipt 
  } from 'wagmi'
import { parseEther } from 'viem'

function Bridge() {
    const { address, isConnected } = useAccount()
    const nft_contract_address = config.erc721TestTokenAddress;
    const settings = {
        apiKey: "kaFl069xyvy3np41aiUXwjULZrF67--t",
        network: Network.ETH_SEPOLIA,
    };
    console.log("Connected to Account: ", address);
    
    const alchemy = new Alchemy(settings);
    const providesr = new ethers.providers.JsonRpcProvider(config.SEPOLIA_RPC_PROVIDER);
    //console.log("providesr: ", providesr);
    const abi = [
        "function balanceOf(address owner) external view returns (uint256)",
    ]; 
  
    const [nftholdings, setNftholdings] = useState({});
    const tempURI = useRef<{ [key: string]: string }[]>([]);
       
    async function getUserNftHoldings(){ 
        const contractAddress = nft_contract_address;
        let CONTRACT = new ethers.Contract(contractAddress, abi, providesr);
        let findMyToken = address;
        const balance = await CONTRACT.balanceOf(findMyToken);
    
        if(balance == 0) {
        console.log("NO NFT FOUND FOR YOUR ADDRESS");
        }
        else {
            const data = await alchemy.nft.getNftsForOwner(address ?? "", {contractAddresses: [contractAddress]});
            console.log("data", data);

            for(let i = 0; i<data.totalCount; i++)
            { 
                const tokenid: string = data.ownedNfts[i].tokenId;
                const originalString = data.ownedNfts[i].rawMetadata?.image;
               // console.log("originalString: ", originalString);

                if (originalString && originalString.startsWith("https")) {

                    tempURI.current.push({ [tokenid]: originalString });  
                    
                }
                else if(!originalString){
                   // console.log("undefined uri");
                    const tokenURIs = data.ownedNfts[i].tokenUri?.raw;
                    //console.log("undefined uri", tokenURIs);
                    // @ts-ignore
                    tempURI.current.push({ [tokenid]: tokenURIs });  
                }
                else {
                    const stringWithoutFirstSevenLetters = originalString?.substring(7);
                    const finalString = "https://ipfs.io/ipfs/" + stringWithoutFirstSevenLetters;
                    tempURI.current.push({ [tokenid]: finalString }); 
                }   
            }
           // console.log(tempURI);
            setNftholdings(tempURI);
            }
            //console.log("tempURI: ", nftholdings);
            //console.log("tempURI type: ",typeof(nftholdings))
    }
    const [selectedTokenID, setSelectedTokenID] = useState<any>(null);
    const [selectedImageURL, setSelectedImageURL] = useState<string | null>(null);

    async function nftSelected(tokenid:any, image:any) {
        console.log("Selected Token ID:", tokenid);
        console.log("Selected Image:", image);
        setSelectedTokenID(tokenid);
        
        if(image)
        {
            setSelectedImageURL(image);
        }
        else
        {
            setSelectedImageURL("https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg");
        }
    }

    useEffect(()=> {
        if(isConnected){
            getUserNftHoldings()
        }

        return(() => {
            // if(isConnected){
            //     console.log("hello");
            //     getUserNftHoldings()
            // }
        })
        
      
    },[isConnected, tempURI])

    /* Bridge Logic */
    const { data: approvalData, status: approvalStatus, handleApprove } = useApproveERC721();
    const { data: depositData, status: depositStatus, handleDeposit } = useDeposit();
    //const status = "";
    async function approve() {
        
        try
        {
            console.log("approvalData: ");
            await handleApprove(config.dragonBridgeAddress, selectedTokenID); 
        }
        catch(e) {
            console.log("Error while bridging")
        }
        
    }
    console.log("approvalStatus", approvalStatus);

    if(approvalStatus=="success"){
        console.log("approvalData: ", approvalData);
        
    }
    const approvalDataresult = useWaitForTransactionReceipt({
        hash: approvalData!,
    })
    //@ts-ignore
    console.log("res: ", approvalDataresult );
    //@ts-ignore
    console.log("result: ", approvalDataresult.queryKey[1].hash );
    // const { isLoading: isConfirming, isSuccess: isConfirmed } = 
    // useWaitForTransactionReceipt({ 
    //     approvalData, 
    // }) 

    async function bridgeTheNft() {
        try
        {
            console.log("Bridge started to erc404");
            await handleDeposit(nft_contract_address, selectedTokenID)
        }
        catch(e) {
            console.log("Error while bridging")
        }
        
    }

    return (
        <>
        <section className="bridge">
            <div className="bridge__container">
                <h1>
                    404 Dragon-Bridge
                </h1>
                <div className="bridge__content">
                    <div className="bridge__content__top">
                        <p>
                            ERC 721
                        </p>

                        <span>
                            TO
                        </span>

                        <p>
                            ERC 404
                        </p>
                    </div>

                    <div className="bridge__content__select">
                        <h2>
                            NFT Selected
                        </h2>
                        {/* <span>
                            +
                        </span> */}

                        {   
                        selectedImageURL? 
                            <>
                                <img className="photo" src={selectedImageURL}/>
                                <p className="phototag">Token ID: {selectedTokenID}</p>
                            </>
                            : 
                            <></> 
                        }
                    </div>

                    <div className="bridge__content__btn">
                        {
                            approvalStatus === "success" ? 
                            <button onClick={bridgeTheNft} className="Success">
                                Transfer
                            </button> 
                            :
                           // @ts-ignore
                           approvalStatus === "pending" && approvalDataresult.queryKey[1].hash!="undefined" && approvalDataresult.status==="pending" ? 
                            <button disabled className="pending">
                                Pending
                            </button>
                            :
                            depositStatus === "success" ? 
                            <>
                            <button onClick={bridgeTheNft} className="SuccessdepositStatus">
                                Bridge successful
                            </button> 
                            </>
                            :
                            <>
                            <button onClick={approve} className="approve">
                                Bridge
                            </button> 
                            </>
                        }
                    </div>
                </div>
            </div>    
        </section>

        {
            nftholdings ? 
            <div className="styleNft">
              {
                tempURI.current.map((item, index) => (
                  <div  key={index}>
                    <div>
                        <img className="photox" src={Object.values(item)[0]} alt={`Image for token ${Object.keys(item)[0]}`} />
                        <p className="phototag">Token ID: {Object.keys(item)[0]}</p>
                        <div className="bridge__content__btnX">
                            <button onClick={() => nftSelected(Object.keys(item)[0], Object.values(item)[0])}>Select</button>
                        </div>
                    </div>
                  </div>
                ))
              }
            </div>
            : 
            <>
            </>
          }
          
        </>

    );


}

export default Bridge;