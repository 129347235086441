import { useState, useCallback } from "react";
import { useWriteContract } from "wagmi";
import { dragonBridgeAbi } from "../abis/dragonBridge";
import config from "../config";
import { abi } from "../abis/dragonv2";

const contractAddress = config.dragonBridgeAddress as `0x${string}`;


function useDeposit() {
  const { data, status, error, writeContract } = useWriteContract();

  const handleDeposit = useCallback(async (tokenAddress: any, tokenId: any) => {
    console.log("Deposit initiated, confirm the transaction with token contract", tokenAddress);

    console.log("tokenAddress: ", tokenAddress);
    console.log("tokenId: ", tokenId);

    try {
      writeContract({
        abi: dragonBridgeAbi,
        address: contractAddress,
        functionName: "depositsERC721",
        args: [tokenAddress, tokenId],
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  console.log("Status:", status)
  return { status, data, handleDeposit };
}

export default useDeposit;
